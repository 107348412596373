/**
 * 优惠券相关API
 */

import request from '@/utils/request'


/**
 * 删除优惠券
 * @param ids
 * @param params
 * @returns {Promise<any>}
 * @constructor
 */
export function deleteCoupons(ids, params) {
  return request({
    url: `seller/promotion/coupons/${ids}`,
    method: 'delete',
    loading: false,
    params
  })
}

/**
 * 删除优惠券
 * @param params
 * @returns {Promise<any>}
 * @constructor
 */
export function cardDelete(params) {
  return request({
    url: `/seller/card/delete`,
    method: 'post',
    loading: false,
    params
  })
}


/**
 * 查询卡卷
 * @param id
 * @returns {Promise<any>}
 */
export function getDhmlist(params) {
  return request({
    url: 'seller/card/list',
    method: 'get',
    loading: false,
    params
  })
}
/**
 * 现金商城优惠券任务
 * 查询优惠券
 * @param {*} params 
 * @returns 
 */
export function getCouponsInfo(params) {
  return request({
    url: 'seller/coupon/list',
    method: 'get',
    loading: false,
    params
  })
}
/**
 * 禁用/启用单个优惠券
 * @param {*} params id: int,status: int
 * @returns 
 */
export function putCouponsUpdateStatuso(params) {
  return request({
    url: 'seller/coupon/updateStatus',
    method: 'put',
    loading: false,
    params
  })
}
/**
 * 添加优惠券
 * @param {*} data 
 * @returns 
 */
export function postCoupons(data) {
  return request({
    headers: {
      'Content-Type':"application/json"
    },
    url: 'seller/coupon',
    method: 'post',
    loading: false,
    data
  })
}
/**
 * 修改优惠券
 * @param {*} data 
 * @returns 
 */
export function putCoupons(data) {
  return request({
    headers: {
      'Content-Type':"application/json"
    },
    url: 'seller/coupon',
    method: 'put',
    loading: false,
    data
  })
}
/**
 * 获取优惠卷商品信息
 * @param {*} data Array
 * @returns 
 */
export function getGoods(data) {
  return request({
    headers: {
      'Content-Type':"application/json"
    },
    url: 'seller/coupon/goods',
    method: 'post',
    loading: false,
    data
  })
}
/**
 * 查询优惠券管理列表
 * @param {*} params 
 * @returns 
 */
export function getCoupons(params) {
  return request({
    url: 'seller/coupon/manage/list',
    method: 'get',
    loading: false,
    params
  })
}
/**
 * 查询兑换码管理列表-详情页
 * @param {*} params 
 * @returns 
 */
export function getCouponKeyList(params) {
  return request({
    url: 'seller/couponKey/list',
    method: 'get',
    loading: false,
    params
  })
} 
/**
 * 在线发放管理-系统生成
 * @param {*} data Array
 * @returns 
 */
export function grantSystemAdd(data) {
  return request({
    headers: {
      'Content-Type':"application/json"
    },
    url: 'seller/couponKey/grant/systemAdd',
    method: 'post',
    loading: false,
    data
  })
}
/**
 * 在线发放管理-表格导入
 * @param {*} data Array
 * @returns 
 */
export function grantTableAdd(data) {
  return request({
    headers: {
      'Content-Type':"application/json"
    },
    url: 'seller/couponKey/grant/SXTableAdd',
    method: 'post',
    loading: false,
    data
  })
}
/**
 * 兑换码管理-系统生成
 * @param {*} data Array
 * @returns 
 */
export function systemAdd(data) {
  return request({
    
    url: 'seller/couponKey/manage/systemAdd',
    method: 'post',
    loading: false,
    data
  })
}
/**
 * 兑换码管理-表格导入
 * @param {*} data Array
 * @returns 
 */
export function tableAdd(data) {
  return request({
    headers: {
      'Content-Type':"application/json"
    },
    url: 'seller/couponKey/manage/tableAdd',
    method: 'post',
    loading: false,
    data
  })
}

/**
 * 查询单个优惠卷
 * @param {*} id 
 * @returns 
 */
export function getSingleCoupons(id) {
  return request({
    url: `seller/coupon/${id}`,
    method: 'get',
    loading: false
  })
}
/**
 * 操作卡密
 * 操作卡密（[0：激活选中]、[1：激活]、[2：激活全部]、[3：禁用选中]、[4：禁用]、[5：禁用全部]、[6：延期]、[7：导入延期数据]）
 * @param {*} data 
 * @returns 
 */
export function putOperateCoupons(data) {
  return request({
    headers: {
      'Content-Type':"application/json"
    },
    url: 'seller/couponKey/operate',
    method: 'put',
    loading: false,
    data
  })
}
/**
 * 查询兑换码操作日志
 * @param {*} params 
 * @returns 
 */
export function getCouponKeyLog(params) {
  return request({
    url: 'seller/couponKey/couponKeyLog/list',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 添加卡卷
 * @param id
 * @returns {Promise<any>}
 */
export function cardAdd(params) {
  return request({
    url: 'seller/card/add',
    method: 'post',
    loading: true,
    data: params
  })
}

/**
 * 编辑卡卷
 * @param id
 * @returns {Promise<any>}
 */
export function cardEdit(id, data) {
  return request({
    url: 'seller/card/edit/' + id,
    method: 'put',
    loading: true,
    data
  })
}

/**
 * 手动导入卡密

 */
export function importCardKeys(params) {
  return request({
    url: 'seller/card/importCardKeys',
    headers: {'Content-Type': 'application/json'},
    method: 'post',
    loading: false,
    data: params
  })
}

/*

查询卡密
*/
export function getDhJcard(params) {
  return request({
    url: 'seller/card/cardKeysList',
    method: 'get',
    loading: false,
    params
  })
}

// 创建卡密
export function createCardKeys(params) {
  return request({
    url: 'seller/card/createCardKeys',
    method: 'post',
    loading: false,
    data: params
  })
}

//查询卡劵生成 日志表
export function cardList(params) {
  return request({
    url: 'seller/card',
    method: 'get',
    loading: false,
    params
  })
}

//卡卷禁用和激活
export function updateCardsKeyStatus(params) {
  return request({
    url: 'seller/card/updateCardsKeyStatus',
    method: 'put',
    loading: false,
    data: params
  })
}

//查询卡卷发放日志表列表
export function getProvideLog(params) {
  return request({
    url: 'seller/card/getProvideLog',
    method: 'get',
    params
  })
}

//在线卡卷发放系统
export function bindingMembers(params) {
  return request({
    url: 'seller/card/bindingMembers',
    method: 'put',
    data: params
  })
}

//卡卷详情
export function getCardmsg(params) {
  return request({
    url: 'seller/card/getCard',
    method: 'get',
    params
  })
}

//修改卡卷
export function editCardmsg(id, params) {
  return request({
    url: `/seller/card/edit/${id}`,
    method: 'put',
    params
  })
}

//
//导入绑定卡密
export function importBindingMembers(params) {
  return request({
    url: 'seller/card/importBindingMembers',
    method: 'post',
    headers: {'Content-Type': 'application/json'},
    data: params
  })
}

//优惠券列表
export function getCouponsList(params) {
  return request({
    url: 'seller/promotion/coupons',
    method: 'get',
    params
  })
}

//添加优惠券
export function addCoupons(params) {
  return request({
    url: 'seller/promotion/coupons',
    method: 'post',
    data: params
  })
}

//修改优惠券
export function modifyCoupons(coupon_id, params) {
  return request({
    url: `seller/promotion/coupons${coupon_id}`,
    method: 'put',
    data: params
  })
}

//查询一个优惠券
export function getCouponDetails(id) {
  return request({
    url: `seller/promotion/coupons${id}`,
    method: 'get',
  })
}

//查询企业下店铺列表
export function getShopChilds() {
  return request({
    url: 'seller/shops/getShopChilds',
    method: 'get',
  })
}

//查询礼品套餐商品列表
export function comboGoods(params) {
  return request({
    url: 'seller/goods/comboGoods',
    method: 'get',
    params
  })
}

//添加商品
export function addComboGoods(params) {
  return request({
    url: 'seller/goods/comboGoods',
    headers: {'Content-Type': 'application/json'},
    method: 'post',
    data: params
  })
}

//方案报价
export function goodsMktList(params) {
  return request({
    url: '/seller/goodsmkt/goodsMkt/page',
    method: 'get',
    params
  })
}

//方案报价详情
export function goodsMktdetail(id) {
  return request({
    url: `/seller/goodsmkt/goodsMkt/${id}`,
    method: 'get',
  })
}

// 修改卡券类型
export function updateStatus(params) {
  return request({
    url: `seller/card/updateStatus`,
    method: 'put',
    params
  })
}

// 查询卡密操作日志列表
export function getCardsKeyOperationLog(params) {
  return request({
    url: `seller/card/cardsKeyOperationLog`,
    method: 'get',
    params
  })
}

// 查询部分商品可用时的选择的商品
export function getSelectedGoods(params) {
  return request({
    url: `seller/promotion/full-discounts/promotionGoods`,
    method: 'get',
    params
  })
}
