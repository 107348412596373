<template>
  <div class="setMeal">
    <el-steps :active="steps" finish-status="success" simple>
      <el-step title="卡券配置"></el-step>
      <el-step title="选择兑换商品"></el-step>
      <el-step title="创建兑换页面"></el-step>
      <el-step title="兑换完成"></el-step>
    </el-steps>

    <!-- 第1步 -->
    <div v-show="steps == 0" class="stepsBox steps0">
      <div class="stepsForm">
        <el-form :model="steps0Info" ref="steps0Info" :rules="steps0Rules" label-width="180px">
          <el-form-item label="卡券名称：" prop="card_name">
            <el-input placeholder="请输入卡券名称" v-model="steps0Info.card_name"></el-input>
          </el-form-item>
          <el-form-item label="有效截止时间：" prop="bbbb">
            <el-date-picker
              v-model="steps0Info.bbbb"
              type="date"
              placeholder="选择日期"
              @change="daterangeDateChange"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="选择绑定商城：" prop="shop_id">
            <el-select v-model="steps0Info.shop_id" placeholder="请选择">
              <el-option
                v-for="item in list_shop"
                :key="item.shop_id"
                :label="item.shop_name"
                :value="item.shop_id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所属销售公司：" prop="company">
            <el-input placeholder="请输入销售公司名称" v-model="steps0Info.company"></el-input>
          </el-form-item>
          <el-form-item label="卡券状态：" prop="status" v-if="!this.$route.query.card_id">
            <el-radio-group v-model="steps0Info.status">
              <el-radio :label="1">上线</el-radio>
              <el-radio :label="0">下线</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <div class="btnAll">
        <el-button type="primary" @click="nuxt('steps0Info',1)" size="small">下一步</el-button>
      </div>
    </div>

    <!-- 第2步 -->
    <div v-show="steps == 1" class="stepsBox steps1">
      <en-table-layout
        toolbar
        pagination
        :tableData="tableData.data"
        @selection-change="handleSelectionChange"
      >
        <div slot="toolbar" class="inner-toolbar">
          <div class="toolbar-btns">
            <div class="conditions">
              <span>会员价：</span>
              <el-input
                style="width: 75px;"
                v-model="params_combo.low_price"
                placeholder="最低价"
                @change="val => params_combo.low_price = isFinite(parseInt(val))?parseInt(val):val.replace(/[A-Za-z\u4e00-\u9fa5]/g,'')"
              ></el-input>
              <span style="margin: 0 5px;">-</span>
              <el-input
                class="center-input"
                style="width: 75px;text-align:center;"
                v-model="params_combo.high_price"
                placeholder="最高价"
                @change="val => params_combo.high_price = isFinite(parseInt(val))?parseInt(val):val.replace(/[A-Za-z\u4e00-\u9fa5]/g,'')"
              ></el-input>
            </div>
            <div class="conditions">
              <el-input placeholder="请输入礼包名称" v-model="params_combo.goods_name"></el-input>
            </div>
            <div class="conditions">
              <el-button type="primary" size="small" @click="searchCombo">搜索</el-button>
              <el-button size="small" @click="resetComboCondition">重置</el-button>
            </div>
            <!-- <div class="conditions conditions1">
              <el-button type="primary" @click="addTcCard" size="small">加入卡券</el-button>
            </div> -->
          </div>
          <div class="toolbar-search">
            <el-button type="primary" @click="createCombo" size="small">新建礼包</el-button>
          </div>
        </div>
        <template slot="table-columns">
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column label="编号">
            <template slot-scope="scope">{{ scope.row.sn }}</template>
          </el-table-column>
          <el-table-column label="礼包名称">
            <template slot-scope="scope">{{ scope.row.goods_name }}</template>
          </el-table-column>
          <el-table-column label="会员价">
            <template slot-scope="scope">￥{{ scope.row.price }}</template>
          </el-table-column>
          <el-table-column label="市场价">
            <template slot-scope="scope">￥{{ scope.row.mktprice }}</template>
          </el-table-column>
          <el-table-column label="利润率">
            <!--  (this.tableData.data[va].price-this.tableData.data[va].cost)/this.tableData.data[va].cost*100 -->
            <template slot-scope="scope">{{ scope.row.enterprise_profit || 0 }}%</template>
          </el-table-column>
          <el-table-column label="操作" width="200">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" @click="seeMeal(scope.row,scope.$index)">查看</el-button>
              <el-button size="mini" type="danger" @click="handleDeleteMeal(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </template>
        <el-pagination
          slot="pagination"
          v-if="tableData"
          @size-change="handlePageSizeChangeCombo"
          @current-change="handlePageCurrentChangeCombo"
          :current-page="tableData.page_no"
          :page-size="tableData.page_size"
          :page-sizes="MixinPageSizes"
          :layout="MixinTableLayout"
          background
          :total="tableData.data_total"
        ></el-pagination>
      </en-table-layout>
      <div class="btnAll">
        <el-button type="primary" @click="prev('steps1Info')" size="small">上一步</el-button>
        <el-button type="primary" @click="nuxt('steps1Info',2)" size="small">下一步</el-button>
      </div>
    </div>

    <!--礼包详情-->
    <el-dialog title="礼包详情" :visible.sync="seeMealShow" width="800px">
      <en-table-layout :toolbar="false" :tableData="seeMealInfo" border>
        <template slot="table-columns">
          <el-table-column label="编号">
            <template slot-scope="scope">{{ scope.row.sn }}</template>
          </el-table-column>
          <el-table-column label="商品名称">
            <template slot-scope="scope">{{ scope.row.goods_name }}</template>
          </el-table-column>
          <el-table-column label="供应商">
            <template slot-scope="scope">{{ scope.row.seller_name }}</template>
          </el-table-column>
          <el-table-column label="会员价">
            <template slot-scope="scope">￥{{ scope.row.price }}</template>
          </el-table-column>
          <el-table-column label="市场价">
            <template slot-scope="scope">￥{{ scope.row.mktprice }}</template>
          </el-table-column>
          <el-table-column label="利润率">
            <!--  (this.tableData.data[va].price-this.tableData.data[va].cost)/this.tableData.data[va].cost*100 -->
            <template slot-scope="scope">{{ scope.row.enterprise_profit || 0 }}%</template>
          </el-table-column>
        </template>
      </en-table-layout>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="seeMealShow = false" size="small">关闭</el-button>
      </div>
    </el-dialog>

    <!--新建礼包-->
    <el-dialog title="新建礼包" :visible.sync="addMealShow" width="900px">
      <div class="addMealForm">
        <el-form :model="addMealInfo" ref="addMealInfo" :rules="addMealRules" label-width="135px">
          <el-form-item label="礼包名称：" prop="goods_name">
            <el-input placeholder="请输入礼包名称" v-model="addMealInfo.goods_name"></el-input>
          </el-form-item>
          <el-form-item label="商品图片：" prop="goods_gallery" style="width: 90%;text-align: left;">
            <el-upload
              ref="addMealInfoUpload"
              class="avatar-uploader goods-images"
              :action="`${MixinUploadApi}?scene=goods`"
              list-type="picture-card"
              multiple
              :on-remove="handleRemove"
              :on-success="handleSuccess"
            >
              <i class="el-icon-plus avatar-uploader-icon"></i>
              <div slot="tip" class="el-upload__tip">建议尺寸：640*640像素，大小不超5MB，可拖拽调整位置，最多上传5张图。</div>
            </el-upload>
          </el-form-item>
          <el-form-item class="w200" label="会员价：" prop="price">
            <el-input-number
              style="width: 200px;"
              :controls="false"
              v-model="addMealInfo.price"
              :min="0"
              placeholder="请输入会员价"
            ></el-input-number>&nbsp;元
          </el-form-item>
          <el-form-item class="w200" label="市场价：" prop="mktprice">
            <el-input-number
              style="width: 200px;"
              :controls="false"
              v-model="addMealInfo.mktprice"
              :min="0"
              placeholder="请输入市场价"
            ></el-input-number>&nbsp;元
          </el-form-item>
          <el-form-item label="礼包详情：" class="goods-intro">
            <UE ref="ue" :defaultMsg="addMealInfo.intro"></UE>
          </el-form-item>
        </el-form>
      </div>
      <el-button class="addBtn" type="primary" size="small" @click="marketShow = true">集市选择商品</el-button>
      <div class="addMealGoods">
        <div class="box" v-for="(item, index) in addMealInfo.combo_goods_list " :key="index">
          <i @click="delGood(index)">X</i>
          <img :src="item.original" />
          <p>{{ item.goods_name }}</p>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleAddComboGoods" size="small">添加到礼包</el-button>
      </div>
    </el-dialog>

    <!--集市商品-->
    <en-goods-picker-fx
        :show="marketShow"
        :goodsApi="'/seller/goods/list-all'"
        :showChooseBtn="false"
        :showCombo="1"
        :default-data="defaultComboData"
        @close="marketShow = false;"
        @choose="choose"
        @chooseAll="chooseAll"
    />

    <div v-if="steps == 2" class="stepsBox steps2">
      <div class="phone">
        <div class="boxAuto">
          <div class="warp imgBox" v-loading="loading_img">
            <img v-if="steps2Info.img_url" :src="steps2Info.img_url" />
            <div v-else class="noimg"></div>
            <!-- 编辑领取页面 -->
            <div class="btn" v-show="btnShow">
              <el-upload
                style="display: inline-block;"
                :action="`${MixinUploadApi}`"
                :show-file-list="false"
                :before-upload="handleImgUrlBefore"
                :limit="1"
                :on-success="uploadedImgUrl"
              >
                <el-button type="text" size="mini">编辑</el-button>
              </el-upload>
              <el-button @click="delImg('img_url')" type="text" size="mini">删除</el-button>
            </div>
          </div>
          <div class="warp videoBox" v-loading="loading_video">
            <!-- <video controls src="https://tiger.cdnja.co/v/qv/qVRGl.mp4?secure=lHRhf2jBgllybiRMvrvqHw&expires=1597048200" style="width:100%;"></video> -->
            <video controls v-if="steps2Info.video_url" :src="steps2Info.video_url" style="width:100%;"></video>
            <div v-else class="novideo"></div>
            <div class="btn" v-show="btnShow">
              <el-upload
                ref="videouploader"
                style="display: inline-block;"
                :action="`${MixinUploadApi}`"
                :data="{scene:'file'}"
                :show-file-list="false"
                :before-upload="handleVideoUrlBefore"
                :limit="1"
                :on-success="uploadedVideoUrl"
              >
                <el-button type="text" size="mini">编辑</el-button>
              </el-upload>
              <el-button @click="delImg('video_url',1)" type="text" size="mini">删除</el-button>
            </div>
          </div>
          <div class="goodsList">
            <h5>
              <!-- <span>添加</span> -->热门兑换
            </h5>
            <div class="box" v-for="(item,index) in steps2Info.list" :key="index">
              <img :src="item.thumbnail" />
              <div class="box-name">{{ item.goods_name }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="btnAll2">
        <el-button type="default" size="small" @click="btnShow = true">编辑领取页面</el-button>
        <!-- <el-button type="default" size="small" @click="tcPageShow = true">编辑套餐页面</el-button> -->
      </div>
      <div class="btnAll">
        <el-button type="primary" size="small" @click="prev('steps1Info')">上一步</el-button>
        <el-button type="primary" size="small" @click="nuxt('steps1Info',3)">下一步</el-button>
      </div>
    </div>

    <!--编辑套餐页面-->
    <!-- <el-dialog title="编辑套餐页面" :visible.sync="tcPageShow" width="800px">
      <div class="tcForm">
        <el-form :model="addMealInfo" ref="addMealInfo" :rules="addMealRules" label-width="95px">
          <el-form-item label="商品名称：" prop="a4a4">
            <el-input style="width: 200px;" placeholder="请输入商品名称" v-model="baseInfoForm.a9a9"></el-input>
          </el-form-item>
          <el-form-item label="商品图片：" prop="goods_gallery" style="width: 90%;text-align: left;">
            <el-upload
              class="avatar-uploader goods-images"
              :action="`${MixinUploadApi}?scene=goods`"
              list-type="picture-card"
              multiple
              :on-remove="handleRemove"
              :on-success="handleSuccess"
            >
              <i class="el-icon-plus avatar-uploader-icon"></i>
              <div slot="tip" class="el-upload__tip">建议尺寸：640*640像素，大小不超5MB，可拖拽调整位置，最多上传5张图。</div>
            </el-upload>
          </el-form-item>
          <el-form-item label="商品详情：" class="goods-intro">
            <UE ref="ue" :defaultMsg="baseInfoForm.intro"></UE>
          </el-form-item>
        </el-form>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="tcPageShow = false" size="small">取消</el-button>
        <el-button type="primary" @click="tcPageShow = false" size="small">确定</el-button>
      </div>
    </el-dialog> -->

    <div v-if="steps == 3" class="stepsBox steps3">
      <div class="phone">
        <div class="boxAuto">
          <div class="warp imgBox">
            <img v-if="steps2Info.img_url" :src="steps2Info.img_url" />
          </div>
          <div class="warp videoBox">
            <video v-if="steps2Info.video_url" :src="steps2Info.video_url"></video>
          </div>
          <div class="goodsList">
            <h5>
              <!-- <span>添加</span> -->热门兑换
            </h5>
            <div class="box" v-for="(item,index) in steps2Info.list" :key="index">
              <img :src="item.thumbnail" />
              <div class="box-name">{{ item.goods_name }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="btnAll">
        <el-button type="primary" size="small" @click="prev('steps1Info')">上一步</el-button>
        <el-button type="primary" size="small" @click="handleAddComplete">完成</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import * as API_goods from "@/api/goods";
import * as API_coupon from "@/api/coupon";
import Sortable from "sortablejs";
import { UE } from "@/components";
import EnTableLayout from '../../../../ui-components/TableLayout/src/main';
export default {
  name: "hotSellingGoods",
  components: {
    EnTableLayout,
    [UE.name]: UE
  },
  data() {
    return {
      steps: 0,
      steps0Info: {},
      list_shop: [],
      list2: [
        {
          value: 1,
          label: "选项1"
        }
      ],
      steps0Rules: {
        card_name: [
          { required: true, message: "请输入卡券名称", trigger: "blur" }
        ],
        bbbb: [
          { required: true, message: "请选择有效截止时间", trigger: "change" }
        ],
        shop_name: [
          { required: true, message: "请选择选择绑定商城", trigger: "change" }
        ],
        company: [
          { required: true, message: "请输入公司名称", trigger: "blur" }
        ]
      },

      // 2
      tableData: {},
      pageData: {},
      params_combo: {
        goods_name: '',
        low_price: '',
        high_price: '',
        page_no:1,
        page_size:5
      },
      params: {
        goods_source: 1
      },
      seeMealShow: false,
      seeMealInfo: [],
      addMealShow: false,
      addMealInfo: {
        goods_name: '',
        /** 卡券关联的套餐商品列表 */
        combo_goods_list:[],
        /** 商品相册列表 */
				goods_gallery_list: [],

				/** 用来校验的商品相册 */
				goods_gallery: ''
      },
      addMealRules: {
        goods_name: [{ required: true, message: "请输入礼包名称", trigger: "blur" }],
        price: [{ required: true, message: "请输入会员价", trigger: "change" }],
        mktprice: [{ required: true, message: "请输入市场价", trigger: "change" }],
        goods_gallery: [
						{ required: true, message: '请选择商品相册', trigger: 'change' }
				],
      },

      marketShow: false,
      marketInfo: {
        sort: ''
      },
      marketList: [],
      marketGoods: [],
      pageData1: {},

      // 3
      steps2Info: {
        list: [
          {
            thumbnail:"https://zhonghaifp.oss-cn-beijing.aliyuncs.com/files/normal/2887E1BD35534E34A072782295FB2F9C.jpg"
          },
          {
            thumbnail:"https://zhonghaifp.oss-cn-beijing.aliyuncs.com/files/normal/2887E1BD35534E34A072782295FB2F9C.jpg"
          },
          {
            thumbnail:"https://zhonghaifp.oss-cn-beijing.aliyuncs.com/files/normal/2887E1BD35534E34A072782295FB2F9C.jpg"
          },
          {
            thumbnail:"https://zhonghaifp.oss-cn-beijing.aliyuncs.com/files/normal/2887E1BD35534E34A072782295FB2F9C.jpg"
          }
        ]
      },
      btnShow: false,
      loading_img: false,
      loading_video: false,
      loading_goods: false,
      tcPageShow: false,

      baseInfoForm: {
        /** 商品相册列表 */
        goods_gallery_list: [],

        /** 用来校验的商品相册 */
        goods_gallery: ""
      },
      idsList: [],
      defaultComboData:[]
    };
  },

  mounted() {
    this.GET_ShopList();
    this.GET_ComboGoodsList();
    this.GET_GoodsList();
    //如果是编辑则回显数据
    const card_id = this.$route.query.card_id
    if(card_id){
      API_coupon.getCardmsg({ card_id }).then(res => {
        this.steps0Info = res;
        this.$set(this.steps0Info,'bbbb',new Date(res.end_time*1000))
        this.steps2Info = res;
      });
    }

  },
  methods: {
    choose(row){
      console.log(row,'choose===row');
      this.defaultComboData=[row]
      this.selectMarketGood([row])
    },
    chooseAll(list){
      console.log(list,'chooseAll===list');
      this.defaultComboData=list
      this.marketGoods=list
      this.selectMarketGood()
    },
    createCombo(){
      this.addMealShow = true
      this.$nextTick(_=>{
        this.$refs.addMealInfo.resetFields()
      })

      this.addMealInfo.combo_goods_list=[]
      this.addMealInfo.goods_gallery_list=[]
      this.addMealInfo.goods_gallery=''
      this.addMealInfo.intro = ''
      this.$nextTick(_=>{
        this.$refs.addMealInfoUpload.clearFiles()
      })
    },
    /**
     * 套餐礼包筛选条件
     */
    searchCombo(){
      if(Number(this.params_combo.high_price).toString() === 'NaN' || isNaN(this.params_combo.low_price)){
        this.$message.error('请输入数字')
        return
      }
      this.GET_ComboGoodsList()
    },
    resetComboCondition(){
      this.params_combo.high_price = ''
      this.params_combo.low_price = ''
      this.params_combo.goods_name = ''
      this.GET_ComboGoodsList()
    },
    /**
     * 集市商品筛选条件
     */
    handleGoodsSortChange(){
      this.GET_GoodsList();
    },
    /**
     * 上一步
     * */
    prev(formName, step) {
      this.steps -= 1;
    },
    /**
     * 下一步
     * */
    nuxt(formName, step) {
      if(step==2){
        if(!this.steps0Info.combo_id||this.steps0Info.combo_id.length===0){
          this.$message.error('请先选择加入卡券的礼包')
          return
        }
      }
      if (this.$refs[formName]) {
        this.$refs[formName].validate(valid => {
          if (valid) {
            this.steps = step;
          }
        });
      } else {
        this.steps = step;
      }
    },
    /**
     * 第二步：处理勾选商品
     */
    handleSelectionChangeMarketGoods(val) {
      this.marketGoods = val;
    },
    /**
     * 第二步：创建套餐商品（即礼包）
     */
    handleAddComboGoods() {
      this.$refs['addMealInfo'].validate(valid => {
          if (valid) {
            this.addMealInfo.intro = this.$refs['ue'].getUEContent()
            if(!this.addMealInfo.intro){
              this.$message.error('请填写礼包详情')
              return
            }
            if(!this.addMealInfo.combo_goods_list||this.addMealInfo.combo_goods_list.length === 0){
              this.$message.error('请挑选加入礼包的商品')
              return
            }
            this.addMealInfo.category_id = 603;
            this.addMealInfo.category_name = "礼品商品";
            this.addMealInfo.template_id = 0;
            this.addMealInfo.goods_transfee_charge = 1;
            this.addMealInfo.shop_cat_id = 0;
            this.addMealInfo.weight = 0;
            this.addMealInfo.is_combo = 0;
            this.addMealInfo.market_enable = 1;
            this.addMealInfo.cost = this.addMealInfo.mktprice;

            API_coupon.addComboGoods(this.addMealInfo).then(res => {
              this.$message.success("添加成功");
              this.addMealShow = false;
              this.GET_ComboGoodsList();
            });
          }
      });
    },
    /**
     * 第二步：加入卡券
     */
    addTcCard() {
      if(this.steps0Info.combo_id&&this.steps0Info.combo_id.length>0){
        this.steps0Info.card_type = 1;
        let time = new Date();
        this.steps0Info.start_time = parseInt(time.getTime() / 1000);
        this.$message.success("加入成功！");
      }else{
        this.$message.error('请先选择加入卡券的礼包')
      }
    },
    /**
     * 第二步：选择存入combo_id
     */
    handleSelectionChange(val) {

      let ids = [];
      let comboList = []
      val.forEach(item => {
        ids.push(item.goods_id);
        comboList.push({goods_name:item.goods_name,thumbnail:item.thumbnail})
      });
      this.steps0Info.combo_id = ids;
      this.steps2Info.list = comboList
    },
    good_list(val) {
      this.params.goods_source = val;
      this.GET_GoodsList();
    },
    //自有商品列表
    GET_GoodsList() {
      this.params.page_no = this.pageData1.page_no;
      this.params.page_size = this.pageData1.page_size;
      this.params.is_combo = 0;
			this.params.disabled=1;
			this.params.market_enable=1;
      let params = {
        ...this.params,
        ...this.marketInfo
      };
      this.loading_goods = true;
      API_goods.getGoodsListq(params).then(response => {
        this.loading_goods = false;
        this.marketList = response.data;
        this.pageData1.page_no = response.page_no;
        this.pageData1.page_size = response.page_size;
        this.pageData1.data_total = response.data_total;
      });
    },
    daterangeDateChange(val) {
      var b = val.getTime() / 1000;
      this.steps0Info.end_time = b;
    },
    GET_ShopList() {
      API_coupon.getShopChilds().then(res => {
        this.list_shop = res;
      });
    },
    GET_ComboGoodsList() {
      API_coupon.comboGoods(this.params_combo).then(res => {
        this.tableData = res;
      });
    },
    /** 套餐分页大小发生改变 */
    handlePageSizeChangeCombo(size) {
      this.params_combo.page_size = size;
      this.GET_ComboGoodsList();
    },

    /** 套餐分页页数发生改变 */
    handlePageCurrentChangeCombo(page) {
      this.params_combo.page_no = page;
      this.GET_ComboGoodsList();
    },
    /** 分页大小发生改变 */
    handlePageSizeChange(size) {
      this.pageData1.page_size = size;
      this.GET_GoodsList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.pageData1.page_no = page;
      this.GET_GoodsList();
    },
    /** 查看商品 */
    seeMeal(row, index) {
      this.seeMealInfo = [];
      this.seeMealShow = true;
      console.log(row)
      const id = row.goods_id
      API_goods.getComboBindGoods(id).then(res => {
        this.seeMealInfo = res
      });
    },
    /** 删除商品 */
    handleDeleteMeal(row) {
      this.$confirm("确认删除此礼包, 是否继续?", "提示", {
        type: "warning"
      }).then(() => {
        const _ids = [row.goods_id].toString();
        API_goods.deleteGoods(_ids).then(() => {
          this.GET_ComboGoodsList();
          this.$message.success("删除礼包成功！");
        });
      });
    },
    delGood(index) {
      this.addMealInfo.combo_goods_list.splice(index, 1);
      this.defaultComboData.splice(index, 1);
    },
    selectMarketGood(list) {
      this.addMealInfo.combo_goods_list = []
      if (list&&list.length) {//挑选单个
        let haveTheSameGoods = false
        // let goodsIds = this.addMealInfo.goods_ids.split(',')

        // for (let index = 0; index < goodsIds.length; index++) {
        //   const e = goodsIds[index];
        //   if(e == list[0].goods_id) {
        //     haveTheSameGoods = true;
        //     break;
        //   }
        // }
        if(!haveTheSameGoods){
          list.forEach(item => {
            if (this.addMealInfo.goods_ids) {
              this.addMealInfo.goods_ids =
                this.addMealInfo.goods_ids + "," + item.goods_id;
            } else {
              this.addMealInfo.goods_ids = item.goods_id;
            }

            item.sort = 0;
            item.original = item.thumbnail;
            item.img_id = -1;
            let arr = {
              img_id: item.img_id,
              original: item.original,
              sort: item.sort,
              goods_name: item.goods_name
            };
            this.addMealInfo.combo_goods_list.push(arr);
          });
        }
        this.marketShow = false;
      } else {//批量挑选
        if(this.marketGoods.length>0){
          this.marketGoods.forEach(item => {
            if (this.addMealInfo.goods_ids) {
              this.addMealInfo.goods_ids =
                this.addMealInfo.goods_ids + "," + item.goods_id;
            } else {
              this.addMealInfo.goods_ids = item.goods_id;
            }
            item.sort = 0;
            item.original = item.thumbnail;
            item.img_id = -1;
            let arr = {
              img_id: item.img_id,
              original: item.original,
              sort: item.sort,
              goods_name: item.goods_name
            };
            this.addMealInfo.combo_goods_list.push(arr);
          });
          this.marketShow = false;
        }else {
          this.$message.error('请选择加入的商品')
        }
      }
    },
    handleImgUrlBefore() {
      this.loading_img = true;
    },
    uploadedImgUrl(response) {
      this.steps2Info.img_url = response.url;
      this.loading_img = false;
    },
    handleVideoUrlBefore() {
      this.loading_video = true;
    },
    uploadedVideoUrl(response) {
      this.steps2Info.video_url = response.url;
      this.loading_video = false;
    },
    delImg(key,type) {
      let info = { ...this.steps2Info };
      this.$set(info, key, "");
      this.steps2Info = info;
      if(type){
        this.$refs.videouploader.clearFiles()
      }
    },
    /** 拖拽改变图片顺序 */
    setSort() {
      const el = document.querySelectorAll(
        "div.avatar-uploader > ul.el-upload-list--picture-card"
      )[0];
      this.sortable = Sortable.create(el, {
        ghostClass: "sortable-ghost",
        setData: function(dataTransfer) {},
        onEnd: evt => {
          let temp = this.addMealInfo.goods_gallery_list[evt.oldIndex];
          this.addMealInfo.goods_gallery_list[
            evt.oldIndex
          ] = this.addMealInfo.goods_gallery_list[evt.newIndex];
          this.addMealInfo.goods_gallery_list[evt.newIndex] = temp;
        }
      });
    },
    /** 文件列表移除文件时的钩子  图片删除校验*/
    handleRemove(file, fileList) {
      this.addMealInfo.goods_gallery_list.forEach((key, index) => {
        if (key.img_id !== -1) {
          if (key.img_id === file.img_id) {
            this.addMealInfo.goods_gallery_list.splice(index, 1);
          }
        } else {
          if (key.name === file.response.name) {
            this.addMealInfo.goods_gallery_list.splice(index, 1);
          }
        }
      });
      if (fileList.length <= 0) {
        this.addMealInfo.goods_gallery_list = [];
        this.addMealInfo.goods_gallery = this.addMealInfo.goods_gallery_list.toString();
      }
      this.$refs.addMealInfo.validateField('goods_gallery')
    },
    /** 文件列表上传成功时的钩子  上传成功校验 */
    handleSuccess(response, file, fileList) {
      this.addMealInfo.goods_gallery_list.push({
        img_id: -1,

        original: response.url,

        url: response.url,

        sort: 0,

        name: response.name
      });
      this.addMealInfo.goods_gallery = this.addMealInfo.goods_gallery_list.toString();
      this.$refs['addMealInfo'].validateField('goods_gallery')
      this.$nextTick(() => {
        this.setSort();
      });
    },
    handleAddComplete() {
      console.log(this.steps0Info.combo_id)
      if(this.steps0Info.combo_id.length>=1){
        this.steps0Info.img_url = this.steps2Info.img_url
        this.steps0Info.video_url = this.steps2Info.video_url
        this.steps0Info.card_type = 1
        this.steps0Info.start_time = parseInt(new Date().getTime() / 1000);
        if(Array.isArray(this.steps0Info.combo_id)){
          this.steps0Info.combo_id = this.steps0Info.combo_id.join(',')
        }
        // this.steps0Info.googd_ids = this.steps0Info.combo_id
        console.log(this.steps0Info, "this.steps0Info");
        if(!this.$route.query.card_id){
          API_coupon.cardAdd(this.steps0Info).then(res => {
            console.log(res, "添加成功");
            this.$message.success("加入成功！");
            this.$router.push('/application/my-grant')
          });
        }else{
          API_coupon.cardEdit(this.$route.query.card_id, this.steps0Info)
          .then(res => {
            this.$message.success("修改成功");
            this.$router.push('/application/my-grant');
          })
          .catch(res => {
            this.$message.error(res.message);
          });
        }
      }else{
        this.$message.warning("请先选择卡券再进行操作");
      }

    }
  }
};
</script>

<style lang="scss" scoped>
/deep/ .el-input-number {
  width: 80px;
}
.el-input {
  width: 220px;
}
.setMeal {
  background: #fff;
  padding: 20px;
  border-radius: 5px;
}
.stepsForm {
  width: 500px;
  padding: 40px 0 30px;
  border-radius: 10px;
  border: 1px solid #e6ebf5;
  margin: 40px auto;
}
.steps0 /deep/ .el-input--small .el-input__inner {
  width: 100%;
}

.btnAll {
  text-align: center;
  margin-top: 35px;
}
.steps1 /deep/ .inner-toolbar {
  padding: 20px 0 10px;
}
.conditions {
  font-size: 14px;
  display: inline-block;
  margin-right: 20px;
}
.steps1 /deep/ .toolbar {
  height: auto;
}
.conditions1 {
  display: block;
  margin-top: 10px;
}
.dialog-footer {
  text-align: center;
}
.addMealForm {
  width: 800px;
  margin: auto;
}
.addMealForm /deep/ .el-input--small .el-input__inner {
  width: 216px;
}
.w200 /deep/ .el-input--small .el-input__inner {
  width: 200px;
}
.addMealGoods {
  width: 510px;
  margin: auto;
}
.addMealGoods .box {
  display: inline-block;
  width: 150px;
  border: 1px solid #e6ebf5;
  position: relative;
  margin: 10px;
}
.addMealGoods .box img {
  display: block;
  width: 100%;
  height: 136px;
}
.addMealGoods .box p {
  background: #f9f9f9;
  line-height: 40px;
  text-align: center;
  padding: 0 6px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0;
  border-top: 1px solid #e6ebf5;
}
.addMealGoods .box i {
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
}
.addBtn {
  margin-left: 135px;
  margin-top: 30px;
  margin-bottom: 10px;
}

.titleTop {
  margin-bottom: 10px;
}
.titleTop .right {
  float: right;
}
.titleTop .right .el-select{
width: 180px !important;
}
.btnAll2 {
  text-align: center;
  margin-bottom: 40px;
}
.phone {
  background: url(../../../assets/iphone.png) no-repeat;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
  width: 350px;
  height: 610px;
  margin: 20px auto;
  margin-top: 40px;
}
.boxAuto {
  width: 313px;
  height: 504px;
  overflow: auto;
  background: #fff;
  position: relative;
  top: 42px;
  left: 19px;
}

.noimg {
  width: 100%;
  height: 200px;
  border-bottom: 1px solid #eee;
  background: url(../../../assets/noimg.png) no-repeat center center;
  -webkit-background-size: 80px auto;
  background-size: 80px auto;
}
.novideo {
  width: 100%;
  height: 200px;
  border-bottom: 1px solid #eee;
  background: url(../../../assets/novideo.png) no-repeat center center;
  -webkit-background-size: 70px auto;
  background-size: 70px auto;
}
.warp {
  position: relative;
}
.warp .btn {
  position: absolute;
  right: 10px;
  top: 10px;
}
.warp img {
  display: block;
  width: 100%;
  min-height: 200px;
}
.goodsList {
  text-align: center;
  padding-bottom: 30px;
}
.goodsList h5 {
  padding: 0 10px;
  font-weight: 500;
  font-size: 14px;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 10px;
}
.goodsList h5 span {
  float: right;
}
.goodsList .box {
  width: 44%;
  margin: 3%;
  border: 1px solid #eee;
  border-radius: 5px;
  float: left;
}
.goodsList .box .box-name {
  padding: 5px;
}
.goodsList .box img {
  display: block;
  width: 100%;
  height: 100px;
}
</style>
<style lang="scss" scoped>
.center-input /deep/ .el-input__inner{
  text-align: center;
}
.goods-images {
  /deep/ li.el-upload-list__item:first-child {
    position: relative;
  }
  /deep/ li.el-upload-list__item:first-child:after {
    content: "封";
    color: #fff;
    font-weight: bold;
    font-size: 12px;
    position: absolute;
    left: -15px;
    top: -6px;
    width: 40px;
    height: 24px;
    padding-top: 6px;
    background: #13ce66;
    text-align: center;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-box-shadow: 0 0 1pc 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 1pc 1px rgba(0, 0, 0, 0.2);
  }
}
</style>
